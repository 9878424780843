import React, {
  lazy,
  Suspense,
  useState,
  createContext,
  useEffect,
  useLayoutEffect,
} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Appbar from "./layouts/Appbar";
// import LandingPage from "./landing page";
import Loader from "./component/Loading";
import CheckExpiry from "./utils/CheckExpiry";
// import IntercomComponent from "./LiveChat/IntercomComponent";
// import ExamCategoryPage from "./ExamCategory";
import Footer from "./layouts/Footer";
import api from "./constants/API";
import { SiteId } from "./constants/constants";
import CouponDisplay from "./Marketing/CouponDisplay";
import PrivateRoute, {
  PrivateRouteCheckout,
  PrivateRoutePayment,
  PrivateAppbar,
  PrivateRouteCart,
} from "./layouts/PrivateRoute";
// import QuestionDialog from "./userdashboard/myExams/webBased/Questions";
// import QueryQuestions from "./userdashboard/myExams/webBased/QueryQuestion";
// import { InvoiceProvider } from "./userdashboard/invoice/InvoiceContext";
// import { InvoiceGenerator } from "./userdashboard/invoice/InvoiceGenerator";
import { useLocation } from "react-router-dom";
import ChatIconLink from "./LiveChat/ChatIconLink";
// import ResetPassword from "./authentication/login/ResetPassword";
import PricingPageSkeleton from "./pricing/PricingPageSkeleton";
import { ExamDetailsSkeleton } from "./exam/ExamDetailsSkeleton";
import HomeSkeletonLoading from "./landing page/HomeSkeletonLoading";
// import Chat from "./LiveChat/Chat";
// import generateSitemaps from "./SiteMap/generateSitemaps";
// import SitemapXML from "./SiteMap/SitemapXML";
// import  from "./userdashboard/myExams/ResultHistory";
const LandingPage = lazy(() => import("./landing page"));
const ExamPage = lazy(() => import("./exam"));
const FAQ = lazy(() => import("./faq"));
const Categories = lazy(() => import("./categories"));
const AboutUs = lazy(() => import("./about-us"));
const ContactUs = lazy(() => import("./contact-us"));
const RefundPolicy = lazy(() => import("./refund-policy"));
const ExamCategoryPage = lazy(() => import("./ExamCategory"));
const PrivacyPolicy = lazy(() => import("./privacy-policy"));
const Pricing = lazy(() => import("./pricing"));
const Cart = lazy(() => import("./cart"));
const Payment = lazy(() => import("./payment"));
const ChackOut = lazy(() => import("./chackout"));
const ChangePassword = lazy(() =>
  import("./userdashboard/changepassword/index")
);
const EditProfile = lazy(() => import("./userdashboard/editprofile/index"));
const OrderHistory = lazy(() => import("./userdashboard/orderhistory/index"));
const PdfDownloads = lazy(() =>
  import("./userdashboard/myExams/pdfDownloads/index")
);
const Invoice = lazy(() => import("./userdashboard/invoice"));
const WebBased = lazy(() => import("./userdashboard/myExams/webBased"));
const ResultHistory = lazy(() =>
  import("./userdashboard/myExams/ResultHistory")
);
const Questions = lazy(() =>
  import("./userdashboard/myExams/webBased/Questions")
);
// const RealModeQuestions = lazy(() =>
//   import("./userdashboard/myExams/webBased/realMode/RealModeQuestions")
// );
const NotFoundError = lazy(() => import("./layouts/NotFoundError"));
const ResetPassword = lazy(() =>
  import("./authentication/login/ResetPassword")
);

export const AppContext = createContext();

const ScrollToTop = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

function App() {
  const userToken = localStorage.getItem("token");
  const currentUser =
    JSON.parse(localStorage.getItem("shoppingUserDetails")) || null;
  const shoppingItems =
    localStorage.getItem("shoppingItems") &&
    JSON.parse(localStorage.getItem("shoppingItems"));
  const userId = localStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [exams, setExams] = useState([]);
  const [discussions, setDiscussions] = useState([]);
  // const [examscategory, setExamsCategory] = useState([]);
  const [token, setToken] = useState(false);
  const [userFirstName, SetFirstName] = useState("");
  const [userFullName, SetuserFullName] = useState("");
  const [cardItems, setCardItem] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [showCart, setshowCart] = useState(false);
  const toggle = () => setshowCart((prevState) => !prevState);
  useLayoutEffect(() => {
    if (CheckExpiry()) {
      const keysToPreserve = [
        "couponExpirationTimestamp",
        "coupon",
        "shoppingItems",
        "bundleshoppingItems",
        "appliedCoupon",
        "promocode",
      ];

      const preservedData = keysToPreserve.reduce((acc, key) => {
        const value = localStorage.getItem(key);
        if (value) acc[key] = value;
        return acc;
      }, {});

      localStorage.clear();

      Object.entries(preservedData).forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
    }
  }, []);

  const setUserToken = (tok) => setToken(tok);

  const setCardItemNumber = (num) => setCardItem(num);

  const setCurrentUserFname = (name) => SetFirstName(name);

  const setCurrentUserFullname = (name) => SetuserFullName(name);

  useLayoutEffect(() => {
    if (userToken) {
      setToken(true);
    } else if (!userToken) {
      setToken(false);
    }
  }, [userToken]);

  useEffect(() => {
    setCardItemNumber(shoppingItems?.length);
  }, [shoppingItems]);

  useEffect(() => {
    SetFirstName(currentUser?.FirstName);
  }, [currentUser]);

  useEffect(() => {
    SetuserFullName(currentUser?.FirstName + " " + currentUser?.LastName);
  }, [currentUser]);

  useEffect(() => {
    getExams();
  }, []);

  // useEffect(() => {
  //   const sitemapContents = generateSitemaps(exams, examscategory);
  //   setSitemapContent(sitemapContents);
  // }, [exams, examscategory]);

  const getExams = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/api/exam/GetExams/15/${SiteId}`);
      setExams(data.Exam.Items);
      setCoupon(data.Coupon.Items[0]);
      setDiscussions(data.Disccusion.Items); // Assuming the response structure you provided
      // console.log("coupon", data.Coupon.Items[0]);
      localStorage.setItem("coupon", JSON.stringify(data.Coupon.Items));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // const getExamCategory = async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await api.get("api/exam/GetExamCategory");
  //     setExamsCategory(data.Exam.Items);
  //     setCategoryTitle(data.CategoryTitleMeta.Items[0].Title);
  //     setCategoryMetaDescription(
  //       data.CategoryTitleMeta.Items[0].MetaDescription
  //     );
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     console.log(err);
  //   }
  // };

  const value = {
    token,
    setUserToken,
    userId,
    cardItems,
    setCardItemNumber,
    userFirstName,
    setCurrentUserFname,
    userFullName,
    setCurrentUserFullname,
    showCart,
    toggle,
    setshowCart,
  };

  const BlogRedirect = () => {
    useEffect(() => {
      window.location.href = "https://wordpress.com/?aff=58022&cid=8348279";
    }, []);
    return null;
  };

  return (
    <div>
      <AppContext.Provider value={value}>
        <Router>
          <PrivateAppbar>
            <CouponDisplay coupon={coupon} />
            {/* <div sx={{ marginTop: "200px" }}> */}
            <Appbar />
            {/* </div> */}
          </PrivateAppbar>
          <ChatIconLink />
          <ScrollToTop />
          <Routes>
            {/* <Route
              exact
              path="/"
              element={<LandingPage loading={loading} exams={exams} />}
            /> */}
             <Route
              exact
              path="/"
              element={
                <React.Suspense fallback={<HomeSkeletonLoading />}>
                  <LandingPage loading={loading} exams={exams} />
                </React.Suspense>
              }
            />
            {/* <Route
              path="/sitemaps.xml"
              element={<SitemapXML xmlContent={sitemapContent} />}
            /> */}
            <Route
              exact
              path="/:examVendorName/:examCode"
              element={
                <React.Suspense fallback={<ExamDetailsSkeleton />}>
                  <ExamPage />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/faqs"
              element={
                <React.Suspense
                  fallback={
                    <div style={{ width: "100%", height: "2650px" }}>
                      <Loader open={loading} />
                    </div>
                  }
                >
                  <FAQ />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/categories"
              element={
                <React.Suspense
                  fallback={
                    <div style={{ width: "100%", height: "600px" }}>
                      <Loader open={loading} />
                    </div>
                  }
                >
                  <Categories />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/about-us"
              element={
                <React.Suspense
                  fallback={
                    <div style={{ width: "100%", height: "2000px" }}>
                      <Loader open={loading} />
                    </div>
                  }
                >
                  <AboutUs />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/contact-us"
              element={
                <React.Suspense
                  fallback={
                    <div style={{ width: "100%", height: "900px" }}>
                      <Loader open={loading} />
                    </div>
                  }
                >
                  <ContactUs />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/resetpassword"
              element={
                <React.Suspense
                  fallback={
                    <div style={{ width: "100%", height: "900px" }}>
                      <Loader open={loading} />
                    </div>
                  }
                >
                  <ResetPassword />
                </React.Suspense>
              }
            />

            <Route
              exact
              path="/refund-policy"
              element={
                <React.Suspense
                  fallback={
                    <div style={{ width: "100%", height: "2400px" }}>
                      <Loader open={loading} />
                    </div>
                  }
                >
                  <RefundPolicy />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/privacy-policy"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <PrivacyPolicy />
                </React.Suspense>
              }
            />

            <Route path="/blog" element={<BlogRedirect />} />
            {/* <Route
              exact
              path="/:category"
              element={<ExamCategoryPage loading={loading} />}
            /> */}
             <Route
              path="/:category"
              element={
                <React.Suspense
                  fallback={
                    <div style={{ width: "100%", height: "2400px" }}></div>
                  }
                >
                  <ExamCategoryPage loading={loading} />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/product-detail/:id"
              element={
                <React.Suspense fallback={<PricingPageSkeleton />}>
                  <Pricing />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/cart"
              element={
                <PrivateRouteCart>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Cart />
                  </Suspense>
                </PrivateRouteCart>
              }
            />
            <Route
              exact
              path="/payment"
              element={
                <PrivateRoutePayment>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <Payment />
                  </React.Suspense>
                </PrivateRoutePayment>
              }
            />
            <Route
              exact
              path="/checkout"
              element={
                <PrivateRouteCheckout>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <ChackOut />
                  </React.Suspense>
                </PrivateRouteCheckout>
              }
            />
            <Route
              exact
              path="/userdashboard/changepassword"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <ChangePassword />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/userdashboard/editProfile"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <EditProfile />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/userdashboard/"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <EditProfile />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/userdashboard/orderHistory"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <OrderHistory />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/userdashboard/myexams/"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <PdfDownloads />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/userdashboard/myexams/pdfDownloads"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <PdfDownloads />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            {/* <Route
              exact
              path="/userdashboard/myexams/DownloadInvoice"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <InvoiceProvider>
                      <Invoice />
                    </InvoiceProvider>
                  </React.Suspense>
                </PrivateRoute>
              }
            /> */}
            <Route
              exact
              path="/userdashboard/myexams/webbased"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <WebBased />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/userdashboard/myexams/webbased/questions/:id"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <Questions />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/userdashboard/myexams/results"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <ResultHistory />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            {/* <Route
              exact
              path="/userdashboard/myexams/webbased/realmode/questions/:id"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <RealModeQuestions />
                  </React.Suspense>
                </PrivateRoute>
              }
            /> */}
            <Route
              path="*"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <NotFoundError />
                </React.Suspense>
              }
            />
            <Route
              path="/error"
              element={
                <React.Suspense fallback={<div>Loading...</div>}>
                  <NotFoundError />
                </React.Suspense>
              }
            />
          </Routes>
          {/* <Chat /> */}
          {/* <IntercomComponent /> */}
          <PrivateAppbar>
            <Footer loading={loading} exams={exams} discussions={discussions} />
          </PrivateAppbar>
        </Router>
      </AppContext.Provider>
    </div>
  );
}

export default App;
