export const API_BASE_URL = String(process.env.REACT_APP_API_BASE_URL);
//export const API_BASE_URL = "https://localhost:44320";
export const STRIPE_CLIENT_KEY =String(process.env.REACT_APP_STRIPE_CLIENT_KEY);
export const STRIPE_RETURN_URL = String(process.env.REACT_APP_STRIPE_RETURN_URL);
//  export const STRIPE_RETURN_URL = "http://localhost:3000/checkout";
export const FACEBOOK_API_KEY = String(process.env.REACT_APP_FACEBOOK_API_KEY);
export const GOOGLE_API_KEY = String(process.env.REACT_APP_GOOGLE_API_KEY);
export const RECAPTCHA_API_KEY = String(process.env.REACT_APP_RECAPTCHA_API_KEY);
export const GOOGLE_SEC = String(process.env.REACT_APP_GOOGLE_SEC);
export const FB_SEC = String(process.env.REACT_APP_FB_SEC);
export const app_id = String(process.env.REACT_APP_APP_ID);
export const tax = String(process.env.REACT_APP_TAX);
export const discount = String(process.env.REACT_APP_DISCOUNT);
export const SellerEmail = String(process.env.REACT_APP_SELLER_EMAIL);
export const SellerName = String(process.env.REACT_APP_SELLER_NAME);
export const CategoryDiscount = String(process.env.REACT_APP_CATEGORY_DISCOUNT);
export const siteUrl = String(process.env.REACT_APP_SITE_URL);
export const SiteId = String(process.env.REACT_APP_SITE_ID);
export const ImagesFolderPath =String(process.env.REACT_APP_IMAGES_FOLDER_PATH);
